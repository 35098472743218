











































































import fakeAuth from '@/lib/fakeAuth';
import Vue from 'vue';
import Splash from '@/components/Splash.vue';

export default Vue.extend({
  components: {
    Splash,
  },

  data() {
    return {
      passcode: "",
      passcodeValid: null as boolean | null,
      fakeLoading: false,
      submitted: false,
    };
  },

  computed: {
    cancelButtons(): string[] {
      return [
        // "I don't have one",
        "Don't have one yet",
        "I'm just visiting",
        "I'll do it later",
        "Where am I?",
      ];
    },
  },

  watch: {
    passcode(newVal: string, oldVal: string): void {
      if (newVal !== oldVal) this.passcodeValid = null;
    },
  },

  methods: {
    onSubmit(): void {
      const { passcode } = this;
      if (!passcode) return;

      // dealwithit.jpg
      this.fakeLoading = true;

      window.setTimeout(() => {
        this.fakeLoading = false;
        this.passcodeValid = !!fakeAuth(passcode);

        if (this.passcodeValid) {
          this.submitted = true;
          window.setTimeout(() => {
            this.$emit("submit", passcode);
          }, 1000);
        } else {
          this.$nextTick(() => {
            this.$refs.passcodeInput.focus();
          });
        }
      }, 500);
    },

    onCancel(): void {
      this.$emit("cancel");
    },
  },
});
