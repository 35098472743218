


















































































































































































import store from '@/store';
import Vue, { PropType } from 'vue';

interface NavItem {
  routeName: string;
  labelHtml: string;
}

export default Vue.extend({
  props: {
    debug: {
      type: Boolean,
      default: false,
    },

    mainItems: {
      type: Array as PropType<(string | Record<string, boolean>)[]>,
      default: () => [],
    },
  },

  data() {
    return {
      showMoreNavItemsList: false,
      // showMoreNavItemsList: true,
      leaving: false,
    };
  },

  computed: {
    authorized(): boolean {
      return store.getters.authorized;
    },

    atHome(): boolean {
      return this.$route.name === 'Home';
    },

    mobileView(): boolean {
      return window.innerWidth < 600;
    },

    navItems(): NavItem[] {
      return [
        { routeName: "Home", labelHtml: "Home" },
        { routeName: "Us", labelHtml: "Us" },
        { routeName: "Faq", labelHtml: "FAQ" },
        { routeName: "Hotels", labelHtml: 'Hotel<span class="hotels-last-letter">s</span>' },
        { routeName: "Map", labelHtml: "Map" },
        { routeName: "Rsvp", labelHtml: "RSVP" },
        { routeName: "Registry", labelHtml: "Registry" },
        { routeName: "Stream", labelHtml: "Stream" },
      ];
    },

    mainItemsEnabled(): Record<string, boolean> {
      const defaults = {
        home: true,
        us: true,
        faq: true,
        rsvp: this.$route.name === "Rsvp" || store.state.userType === "irl",
        stream: this.$route.name === "Stream" || store.state.userType === "streamer",
        hotels: this.$route.name === "Hotels" || false,
        map: this.$route.name === "Map" || false,
      };

      return this.mainItems.reduce<Record<string, boolean>>((memo, item) => ({
        ...memo,
        ...(typeof item === "string" ? { [item]: true } : item),
      }), defaults);
    },

    mainNavItems(): NavItem[] {
      return this.navItems.filter(({ routeName }) => {
        if (!store.getters.authorizedFor(routeName)) return false;
        if (this.$route.name === "Home") return true;
        const name = routeName.toLowerCase();
        return this.authorizedFor(name) && this.mainItemsEnabled[name];
      });
    },

    moreNavItems(): NavItem[] {
      return this.navItems.filter(({ routeName }) => {
        if (!store.getters.authorizedFor(routeName)) return false;
        if (this.$route.name === "Home") return false;
        const name = routeName.toLowerCase();
        return this.authorizedFor(name) && !this.mainItemsEnabled[name];
      });
    },

    hasMoreNavItems(): boolean {
      return !!this.moreNavItems.length;
    },
  },

  methods: {
    showAuthSplash(): void {
      store.commit("showAuth");
    },

    forgetMe(): void {
      localStorage.clear();
      window.location.reload();
    },

    authorizedFor(routeName: string): boolean {
      return store.getters.authorizedFor(routeName);
    },

    onBlurMoreItemsList(): void {
      setTimeout(() => {
        if (this.leaving) return;
        this.showMoreNavItemsList = false;
      }, 100);
    },
  },
});
