













import Vue from 'vue';
import store from '@/store';
import AuthSplash from '@/components/AuthSplash.vue';

export default Vue.extend({
  components: {
    AuthSplash,
  },

  methods: {
    authorize(passcode: string): void {
      store.commit("authorize", passcode);
      const { intendedDestination } = store.state;
      if (
        intendedDestination
        && intendedDestination !== this.$route.name
        && store.getters.authorizedFor(intendedDestination)
      ) {
        this.$router.push({ name: intendedDestination });
      }
    },

    deferAuth(): void {
      store.commit("deferAuth");
    },
  },
});
