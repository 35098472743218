





































import Vue from 'vue';
import MainNav from '@/components/MainNav.vue';
import Sky from '@/components/Sky.vue';
import { calcDayElapsed } from '@/lib/sky/chrono';
import { calcSunlightColorValue } from '@/lib/sky/chroma';
import store from '@/store';
import { imageIsLoaded, loadImage } from '@/lib/images';

export default Vue.extend({
  name: 'Home',

  components: {
    MainNav,
    Sky,
  },

  data() {
    const { innerWidth, innerHeight } = window;

    const treeImageSrc = store.state.webpSupported
      ? require('@/assets/tree_q90_lossy.webp')
      : require('@/assets/tree.png');

    const picnicTableImageSrc = store.state.webpSupported
      ? require('@/assets/picnic_table_with_us_q90_lossy.webp')
      : require('@/assets/picnic_table_with_us.png');

    const grassImageSrc = store.state.webpSupported
      ? require('@/assets/grass_q90_lossy.webp')
      : require('@/assets/grass.png');

    const treeImageLoaded = imageIsLoaded(treeImageSrc);
    const picnicTableImageLoaded = imageIsLoaded(picnicTableImageSrc);
    const grassImageLoaded = imageIsLoaded(grassImageSrc);

    // Should be:
    // - false in prerender
    // - true if document.fonts is not supported
    // - true if Glass Antiqua is loaded already, false if it isn't
    const fontsLoaded = !store.state.inPrerender
      && (document.fonts?.check ? document.fonts.check('12px "Glass Antiqua"') : true);

    return {
      innerWidth,
      innerHeight,
      dayElapsedPercent: calcDayElapsed(),
      debug: false,
      treeImageSrc,
      picnicTableImageSrc,
      grassImageSrc,
      treeImageLoaded,
      picnicTableImageLoaded,
      grassImageLoaded,
      fontsLoaded,
    };
  },

  computed: {
    titleRedValue(): number {
      return this.sunlightColorValue(180, 255);
    },

    titleGreenValue(): number {
      return this.sunlightColorValue(230, 255);
    },

    titleBlueValue(): number {
      return this.sunlightColorValue(235, 255);
    },

    titleOpacity(): number {
      return 1;
    },

    titleRgba(): string {
      const red = this.titleRedValue.toFixed(2);
      const green = this.titleGreenValue.toFixed(2);
      const blue = this.titleBlueValue.toFixed(2);
      const opacity = this.titleOpacity.toFixed(2);
      return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    },

    navLinkRgba(): string {
      return this.titleRgba;
    },

    navLinkActiveRedValue(): number {
      return this.sunlightColorValue(0, 60);
    },

    navLinkActiveGreenValue(): number {
      return this.sunlightColorValue(180, 140);
    },

    navLinkActiveBlueValue(): number {
      return this.sunlightColorValue(0, 60);
    },

    navLinkActiveOpacity(): number {
      return 1;
    },

    navLinkActiveRgba(): string {
      const red = this.navLinkActiveRedValue.toFixed(2);
      const green = this.navLinkActiveGreenValue.toFixed(2);
      const blue = this.navLinkActiveBlueValue.toFixed(2);
      const opacity = this.navLinkActiveOpacity.toFixed(2);
      return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    },

    titleStyles(): Partial<CSSStyleDeclaration> {
      return {
        color: this.titleRgba,
      };
    },

    treeStyles(): Partial<CSSStyleDeclaration> {
      if (!this.treeImageLoaded || store.state.inPrerender) return {};

      return {
        backgroundImage: `url('${this.treeImageSrc}')`,
        filter: 'blur(0px)',
      };
    },

    picnicTableStyles(): Partial<CSSStyleDeclaration> {
      if (!this.picnicTableImageLoaded || store.state.inPrerender) return {};

      return {
        backgroundImage: `url('${this.picnicTableImageSrc}')`,
        filter: 'blur(0px)',
      };
    },

    grassStyles(): Partial<CSSStyleDeclaration> {
      if (!this.grassImageLoaded || store.state.inPrerender) return {};

      return {
        backgroundImage: `url('${this.grassImageSrc}')`,
        filter: 'blur(0px)',
      };
    },

    styleVariables(): Record<string, string> {
      return {
        '--nav-font-color': this.navLinkRgba,
        '--nav-font-color-active': this.navLinkActiveRgba,
      };
    },
  },

  created(): void {
    this.loadFonts();
    this.loadTreeImage();
    this.loadGrassImage();
    this.loadPicnicTableImage();
  },

  mounted(): void {
    (this.$el as HTMLElement).focus();
  },

  beforeRouteUpdate(_to, _from, next): void {
    (this.$el as HTMLElement).focus();
    next();
  },

  methods: {
    sunlightColorValue(atMidnight: number, atNoon: number): number {
      return calcSunlightColorValue(this.dayElapsedPercent, atMidnight, atNoon);
    },

    loadTreeImage(): void {
      if (store.state.inPrerender || this.treeImageLoaded) return;

      loadImage(this.treeImageSrc).then(() => {
        this.treeImageLoaded = true;
      });
    },

    loadPicnicTableImage(): void {
      if (store.state.inPrerender || this.picnicTableImageLoaded) return;

      loadImage(this.picnicTableImageSrc).then(() => {
        this.picnicTableImageLoaded = true;
      });
    },

    loadGrassImage(): void {
      if (store.state.inPrerender || this.grassImageLoaded) return;

      loadImage(this.grassImageSrc).then(() => {
        this.grassImageLoaded = true;
      });
    },

    loadFonts(): void {
      if (document.fonts && !store.state.inPrerender && !this.fontsLoaded) {
        document.fonts.addEventListener('loadingdone', () => {
          this.fontsLoaded = document.fonts.check('12px "Glass Antiqua"');
        });
        document.fonts.ready.then(() => {
          this.fontsLoaded = document.fonts.check('12px "Glass Antiqua"');
        });
      }
    },
  },
});
